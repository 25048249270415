<template>
  <!-- 日常数据 -->
  <div class="main-container">
    <div class="side-header">
      <div class="name">日常数据</div>
    </div>
    <div class="main-content">
      <div class="top flex flex-between">
        <div class="pending">
          <div class="title">待处理</div>
          <div class="task flex flex-center">
            <div class="item">
              <div class="taskTitle">
                <el-link
                  :underline="false"
									@click="jump('OrderManage')"
                  target="_blank"
                  >待支付<i class="el-icon-view el-icon-arrow-right"></i
                ></el-link>
              </div>
              <div class="taskNumber">{{ statistics.unpaid }}</div>
            </div>
            <div class="item">
              <div class="taskTitle">
                <el-link
                  :underline="false"
									@click="jump('StudentInfo')"
                  target="_blank"
                  >待联系<i class="el-icon-view el-icon-arrow-right"></i
                ></el-link>
              </div>
              <div class="taskNumber">{{ statistics.unContact }}</div>
            </div>
          </div>
        </div>
        <div class="TodayData">
          <div class="title">今日数据</div>
          <div class="update-time">更新与：{{ curTime }}</div>
          <div class="task flex flex-center">
            <div class="item">
              <div class="taskTitle">支付金额</div>
              <div class="newNumber">
                <span class="txt symbol">￥</span>
                <span class="nubmer">{{ statistics.todayAmount }}</span>
              </div>
              <div class="oldNumber">
                <span class="txt">昨日</span>
                <span class="symbol">￥</span>
                <span class="nubmer">{{ statistics.yesterdayAmount }}</span>
              </div>
            </div>
            <div class="item">
              <div class="taskTitle">订单数</div>
              <div class="newNumber black">
                {{ statistics.todayOrderCount }}
              </div>
              <div class="oldNumber">
                <span class="txt">昨日</span>
                <span class="nubmer">{{ statistics.yesterdayOrderCount }}</span>
              </div>
            </div>
            <div class="item">
              <div class="taskTitle">指标数</div>
              <div class="newNumber black">
                {{ target.todayFinish }}/{{ target.count }}
              </div>
              <div class="oldNumber">
                <span class="txt">昨日</span>
                <span class="nubmer">{{ target.yesterdayFinish }}/{{ target.count }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="historical-trend">
          <div class="title">历史趋势</div>
          <div class="trend-form">
            <el-form ref="form" label-width="80px">
              <el-form-item label="统计指标">
                <el-select v-model="indicator" placeholder="请选择指标" @change="changeAmountList">                
                  <el-option
                    v-for="item in indicatorValue"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="日期选择">                
                <el-date-picker
                  v-model="time"
                  type="daterange"
                  @change="datepickerchange"
                  :picker-options="pickeroptions"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
          <div id="myChart" :style="{ width: '100%', height: '300px' }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      curTime: "",
      statistics: {},
      target: {},
      indicator:{},
      indicatorValue:[
        {
          label: "默认查询",
          value: 1,
        },
        {
          label: "查询订单数量",
          value: 2,
        }
      ],
      echartsData:{},  
      time:{} ,
      startTime:'',
      endTime:'',
      pickeroptions: {
        // 点击时，选择的是开始时间，也就是mindate
        onpick: ({ maxdate, mindate }) => {
          this.selectdata = mindate.gettime()
          if (maxdate) {
            // 解除限制
            this.selectdata = ''
          }
        },
        disabledDate: (time) => {
          // 设置可选择的日期为今天之后的10天内
          const curDate = (new Date()).getTime()
          // 这里算出一个月的毫秒数,这里使用30的平均值,实际中应根据具体的每个月有多少天计算
          const day = 10 * 24 * 3600 * 1000
          const dateRegion = curDate + day
          return time.getTime() > Date.now() - 8.64e7 || //- 8.64e7 表示可选择当天时间
          time.getTime() > dateRegion
        }
      }  
    }
  },
  watch: {
    time(newData) {
      if (!newData) {
        newData = []
        this.startTime = '' //开始日期
        this.endTime = '' //结束日期      
      }
    },
  },
  created() {
    this.getStatisticsInfo();
    this.getAmountList();  
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById("myChart"));
      // 绘制图表
      myChart.setOption({
        // title: { text: '在Vue中使用echarts' },
        tooltip: {},
        grid: {
          top: '4%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          boundaryGap: false,
          data: this.echartsData.date,
          axisLabel: {
            margin: 20,
          },
          splitLine:{
            show:true,
            color:'#eaeaea',
            lineStyle:{
                type:'dashed'
            }
          }
        },
        yAxis: {
          type: "value",
          splitLine:{
            show:true,
            color:'#eaeaea',
            lineStyle:{
                type:'dashed'
            }
          }
        },
        series: [
          {
            name: "订单数",
            data: this.echartsData.data,
            type: "line",
            symbol: "circle",
            symbolSize: 6,
            areaStyle: {
							color: "rgba(78,165,235,0.30)",
            },
						color: "#4EA5EB",
						lineStyle: {
							color: "#84c0f190", //改变折线颜色
						},
          },
        ],
      });
    },
    //获取接口
    getStatisticsInfo() {
      let date = new Date(),
      time = date.toLocaleTimeString();
      this.$http.get("/api/web/company/getStatisticsInfo").then((res) => {
        this.statistics = res.data.statistics;
        this.target = this.statistics.target;
        this.curTime = time;
      });
    },
    //统计
    getAmountList(){
      this.$http
      .get(`/api/web/company/getAmountList?type=${this.indicator}&startTime=${this.startTime}&endTime=${this.endTime}`)
      .then((res) => {
        this.echartsData = res.data.statistics;   
        this.drawLine();          
      });
    },
    changeAmountList(){      
      this.getAmountList()
    },
		//跳转
		jump(name,id){
			this.$router.push(
				{name,params: {id:'1'},
			})
		},
    datepickerchange(value){
      if (value == null) value = '' ;  
      this.startTime = new Date(this.time[0]).getTime()/1000
      this.endTime = new Date(this.time[1]).getTime()/1000
      this.getAmountList()
    },
    
  },
};
</script>

<style lang="less" scoped>
.main-container {
  .side-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
    padding: 0 20px 0 40px;
    background-color: white;

    .name {
      font-size: 16px;
      color: #333;
    }
  }

  .main-content {
    margin-top: 20px;
    .top {
      margin-bottom: 20px;
      .pending {
        width: 400px;
        height: 290px;
        background-color: white;
        padding: 30px 40px 0;
        .title {
          font-size: 18px;
          font-weight: 400;
          text-align: left;
          color: #333333;
          margin-bottom: 40px;
        }
        .task {
          position: relative;
          &::before {
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            bottom: 0;
            width: 1px;
            height: 60px;
            background: #eaeaea;
          }
          /deep/.item {
            width: 160px;
            padding: 20px 0 0;
            text-align: center;
            .taskNumber {
              width: 100%;
              font-size: 30px;
              font-weight: 400;
              color: #333333;
              margin-top: 30px;
            }
            .taskTitle {
              font-size: 16px;
              color: #666;
              .el-link {
                font-size: 16px;
              }
            }
          }
        }
      }
      .TodayData {
        width: 630px;
        height: 290px;
        background-color: white;
        padding: 30px 40px 0;
        position: relative;
        .title {
          font-size: 18px;
          font-weight: 400;
          text-align: left;
          color: #333333;
          margin-bottom: 35px;
        }
        .update-time {
          position: absolute;
          right: 40px;
          top: 35px;
          font-size: 14px;
          text-align: left;
          color: #666666;
        }
        .task {
          /deep/.item {
            width: 185px;
            position: relative;
            text-align: center;
            padding: 20px 0 0;
            &::before {
              content: "";
              display: block;
              position: absolute;
              bottom: 50%;
              right: 0;
              margin-bottom: -30px;
              width: 1px;
              height: 60px;
              background: #eaeaea;
            }
            &:last-child::before {
              display: none;
            }
            .taskTitle {
              font-size: 16px;
              height: 32px;
              line-height: 32px;
              color: #666666;
              .el-link {
                font-size: 16px;
                color: #666666;
              }
            }
            .newNumber {
              font-size: 28px;
              color: #d71a18;
              height: 48px;
              line-height: 48px;
              letter-spacing: 1px;
              margin: 10px 0;
            }
            .black {
              color: #333333;
            }
            .oldNumber {
              font-size: 14px;
              color: #666666;
            }
          }
        }
      }
    }
    .bottom {
      background-color: white;
      .historical-trend {
        padding: 30px 40px 0;
        height: 570px;
        .title {
          font-size: 18px;
          text-align: left;
          color: #333333;
          margin-bottom: 40px;
        }
        /deep/.trend-form {
          .el-form-item__content {
            text-align: left;
          }
          .el-date-editor .el-range-separator{
            padding: 0;
          }
        }
      }
    }
  }
}
</style>
